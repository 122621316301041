import * as React from 'react';
import styled from '@emotion/styled';

import { Box, Heading, UnstyledAnchor, themeProps } from 'components/design-system';
import { logEventClick } from 'utils/analytics';

import FacebookIcon from '../../assets/Icons/FacebookIcon';
import InstagramIcon from '../../assets/Icons/InstagramIcon';
import TwitterIcon from '../../assets/Icons/TwitterIcon';

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: ${themeProps.space.md}px;

  ${themeProps.mediaQueries.md} {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(${themeProps.widths.xl}px / 3 - 48px), 1fr)
    );
    grid-gap: ${themeProps.space.lg}px;
  }
`;

interface SocialMediaCardProps {
  bg: string;
  icon?: React.ReactNode;
}
const SocialMediaCard: React.FC<SocialMediaCardProps> = ({ bg, icon, children }) => {
  return (
    <Box bg={bg} borderRadius={8} display="flex" flexDirection="row" alignItems="center">
      <Box p="sm" display="flex" alignItems="center" justifyContent="center" width={48} height={48}>
        {icon}
      </Box>
      <Box p="sm" pl={0} display="flex" flex="1 1 auto" alignItems="center" height={48}>
        <Heading variant={500} as="h3" color={themeProps.colors.foreground}>
          {children}
        </Heading>
      </Box>
    </Box>
  );
};

const SocialMediaSection: React.FC = () => {
  const SectionHeading = styled(Heading)`
    margin-bottom: ${themeProps.space.md}px;

    ${themeProps.mediaQueries.md} {
      margin-bottom: ${themeProps.space.xl}px;
    }
  `;

  return (
    <Box mb="xxl">
      <SectionHeading variant={800} as="h2">
        Ikuti kami di
      </SectionHeading>
      <GridWrapper>
        <UnstyledAnchor
          href="https://instagram.com/kawalcovid19.id"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logEventClick('Instagram')}
        >
          <SocialMediaCard bg="#d21c86" icon={<InstagramIcon fill="#f1f2f3" height={24} />}>
            Instagram
          </SocialMediaCard>
        </UnstyledAnchor>
        <UnstyledAnchor
          href="https://twitter.com/KawalCOVID19"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logEventClick('Twitter')}
        >
          <SocialMediaCard bg="#1da1f2" icon={<TwitterIcon fill="#f1f2f3" height={20} />}>
            Twitter
          </SocialMediaCard>
        </UnstyledAnchor>
        <UnstyledAnchor
          href="https://www.facebook.com/KawalCOVID19"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => logEventClick('Facebook')}
        >
          <SocialMediaCard bg="#4267b2" icon={<FacebookIcon fill="#f1f2f3" height={24} />}>
            Facebook
          </SocialMediaCard>
        </UnstyledAnchor>
      </GridWrapper>
    </Box>
  );
};

export default SocialMediaSection;
