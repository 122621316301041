import * as React from 'react';
import styled from '@emotion/styled';

import { Box, Heading, Stack, UnstyledAnchor, themeProps } from 'components/design-system';
import { LinkCard } from 'components/layout';
import importantLinks from 'content/importantLinks.json';

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);

  ${themeProps.mediaQueries.md} {
    grid-column-gap: ${themeProps.space.lg}px;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(${themeProps.widths.xl}px / 3 - 48px), 1fr)
    );
  }
`;

const ImportantLinksSection: React.FC = () => (
  <Stack spacing="xl" mb="xxl">
    <Heading variant={800} as="h2">
      <UnstyledAnchor href="/pranala">Pranala-pranala Penting</UnstyledAnchor>
    </Heading>
    <Stack spacing="xl">
      {Object.entries(importantLinks).map(([sub, links]) => (
        <Stack key={sub} spacing="xs">
          <Heading variant={600} as="h3">
            {sub}
          </Heading>
          <GridWrapper>
            {links.map(({ name, link }) => (
              <LinkCard key={name} name={name} link={link} />
            ))}
          </GridWrapper>
        </Stack>
      ))}
    </Stack>
  </Stack>
);

export default ImportantLinksSection;
