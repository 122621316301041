import * as React from 'react';
import styled from '@emotion/styled';
import { Box, themeProps } from 'components/design-system';

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: ${themeProps.space.md}px;

  ${themeProps.mediaQueries.md} {
    grid-area: articles;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(${themeProps.widths.xl}px / 2 - 48px), 1fr)
    );
    grid-gap: ${themeProps.space.lg}px;
  }
`;

const ArticlesListGrid: React.FC = ({ children }) => {
  return <GridWrapper>{children}</GridWrapper>;
};

export default ArticlesListGrid;
