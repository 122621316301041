import * as React from 'react';
import { HomepageWidget } from 'components/layout';
import { WordPressPostIndex, WordPressUser } from 'types/wp';
import { DekontaminasiHoaxPosts } from 'types/dekontaminasi';
import { PostIndexCard } from '../posts-index';
import ArticlesListGrid from './ArticlesListGrid';

export interface CategorySectionProps {
  posts?: (WordPressPostIndex | DekontaminasiHoaxPosts)[];
  authors?: Record<number, WordPressUser>;
  slug?: string;
  title: string;
  learnMore?: string;
  hasExcerpt?: boolean;
}

const CategorySection: React.FC<CategorySectionProps> = ({
  posts,
  authors,
  slug,
  title,
  learnMore,
  hasExcerpt,
  ...rest
}) => {
  return posts?.length ? (
    <HomepageWidget
      title={title}
      readMoreText={learnMore}
      href="/category/[slug]"
      as={`/category/${slug}`}
      {...rest}
    >
      <ArticlesListGrid>
        {posts.map(post => (
          <PostIndexCard
            key={
              (post as WordPressPostIndex).slug
                ? (post as WordPressPostIndex).slug
                : (post as DekontaminasiHoaxPosts).url
            }
            post={post}
            author={
              (post as WordPressPostIndex).author
                ? authors?.[(post as WordPressPostIndex).author]
                : undefined
            }
            hasExcerpt={hasExcerpt}
          />
        ))}
      </ArticlesListGrid>
    </HomepageWidget>
  ) : null;
};

export default CategorySection;
