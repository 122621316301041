import * as React from 'react';
import styled from '@emotion/styled';
import { themeProps, Heading, Paragraph } from 'components/design-system';
import { Column, Content } from 'components/layout';

const Section = Content.withComponent('section');

const Root = styled(Section)`
  padding: ${themeProps.space.xl}px ${themeProps.space.md}px ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    padding: 6vh ${themeProps.space.md}px 4vh;
  }

  ${themeProps.mediaQueries.md} {
    padding: 6vh ${themeProps.space.lg}px 4vh;
  }

  ${themeProps.mediaQueries.lg} {
    padding: 12vh ${themeProps.space.lg}px 8vh;
  }
`;

const Hero: React.FC = () => {
  return (
    <Root noPadding>
      <Column>
        <Heading variant={900} maxWidth={800} as="h1">
          Kawal informasi seputar COVID-19 secara tepat dan akurat.
        </Heading>
        <Paragraph variant={400} mt="lg" maxWidth={800}>
          Situs ini merupakan sumber informasi inisiatif sukarela warganet Indonesia pro-data,
          terdiri dari praktisi kesehatan, akademisi & profesional.
        </Paragraph>
      </Column>
    </Root>
  );
};

export default Hero;
