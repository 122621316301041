import * as React from 'react';
import useSWR from 'swr/dist';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';

import { Box, themeProps, Text, DataCard, BoxProps } from 'components/design-system';
import { HomepageWidget } from 'components/layout';
import { fetch } from 'utils/api';
// import { logEventClick } from 'utils/analytics';
import formatTime from 'utils/formatTime';
import DekontaminasiStatsResponse, {
  DEKONTAMINASI_STATS_API_URL,
  DEKONTAMINASI_STATS_TIMESTAMP_API_URL,
} from 'types/dekontaminasi';

const ERROR_TITLE = 'Error - Gagal mengambil data terbaru';
const ERROR_MESSAGE = 'Gagal mengambil data. Mohon coba lagi dalam beberapa saat.';

// TODO: Reenable "Statistik Harian" link when AWS migration has been completed

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-gap: ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export interface CasesSectionBlockProps {
  data?: DekontaminasiStatsResponse;
  error: boolean;
  timestampData?: number;
  timestampError: boolean;
}

const CasesSectionBlock: React.FC<CasesSectionBlockProps> = React.memo(
  ({ data, error, timestampData, timestampError, ...rest }) => {
    if (error) {
      Sentry.withScope(scope => {
        scope.setTag('api_error', 'case_summary');
        Sentry.captureException(error);
      });
    }

    const DetailsWrapper = styled(Box)`
      ${themeProps.mediaQueries.md} {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    `;

    const lastUpdatedAt = !timestampError && timestampData;

    return (
      <HomepageWidget title="Jumlah Kasus di Indonesia Saat Ini" {...rest}>
        <GridWrapper>
          <DataCard color="chart" value={data?.numbers.infected} label="Terkonfirmasi" />
          <DataCard
            color="warning02"
            value={data && data.numbers.infected - data.numbers.recovered - data.numbers.fatal}
            label="Dalam Perawatan"
          />
          <DataCard color="success02" value={data?.numbers.recovered} label="Sembuh" />
          <DataCard color="error02" value={data?.numbers.fatal} label="Meninggal" />
        </GridWrapper>
        <DetailsWrapper>
          <Box mt="md">
            <Text as="h5" m={0} variant={200} color="accents04" fontWeight={400}>
              {error ? ERROR_TITLE : 'Pembaruan Terakhir'}
            </Text>
            <Text as="p" variant={400} color="accents07" fontFamily="monospace">
              {lastUpdatedAt ? formatTime(new Date(lastUpdatedAt), 'longest') : ERROR_MESSAGE}
            </Text>
          </Box>
        </DetailsWrapper>
      </HomepageWidget>
    );
  }
);

const CasesSection: React.FC<BoxProps> = props => {
  const { data, error } = useSWR<DekontaminasiStatsResponse | undefined>(
    DEKONTAMINASI_STATS_API_URL,
    fetch
  );
  const { data: timestampData, error: timestampError } = useSWR<
    DekontaminasiStatsResponse | undefined
  >(DEKONTAMINASI_STATS_TIMESTAMP_API_URL, fetch);
  return (
    <CasesSectionBlock
      data={data}
      error={!!error}
      timestampData={Number(timestampData)}
      timestampError={!!timestampError}
      {...props}
    />
  );
};

export default CasesSection;
