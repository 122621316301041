import * as React from 'react';
import useSWR from 'swr/dist';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import { Box, themeProps, Text, BoxProps } from 'components/design-system';
import { HomepageWidget } from 'components/layout';
import { fetch } from 'utils/api';
import formatNumber from 'utils/formatNumber';
import formatTime from 'utils/formatTime';
import { CEKDIRI_API_URL, VaksinResponse, VaksinStats } from 'types/cekdiri';

const EMPTY_DASH = '----';
const ERROR_TITLE = 'Error - Gagal mengambil data terbaru';
const ERROR_MESSAGE = 'Gagal mengambil data. Mohon coba lagi dalam beberapa saat.';

const GridWrapper = styled(Box)`
  display: grid;
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: ${themeProps.space.md}px;

  ${themeProps.mediaQueries.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

// tk = tenaga kesehatan
// ls = lanjut usia
// pp = petugas publik
// mu = masyarakat umum
// ku = kelompok usia 12-17 tahun

export interface VaksinasiProps {
  judul: string;
  total?: number;
  totalSasaran?: number;
  all?: number;
  tkPercentage?: number;
  lsPercentage?: number;
  ppPercentage?: number;
  muPercentage?: number;
  kuPercentage?: number;
  tk?: number;
  ls?: number;
  pp?: number;
  mu?: number;
  ku?: number;
}

const percentage = (item: number, sasaran: number) => {
  const result = (item / sasaran) * 100;
  return result > 100 ? 100 : result;
};

const VaksinTextPercentage = ({
  title,
  color,
  percentageValue,
}: {
  title: string;
  color: string;
  percentageValue: string;
}) => (
  <Box display="flex" flexDirection="row" justifyContent="space-between" mt="xs">
    <Text variant={500} color={color}>
      {title}
    </Text>
    <Text variant={500} color={color}>
      {percentageValue}
    </Text>
  </Box>
);

const Vaksinasi: React.FC<VaksinasiProps> = ({
  judul,
  all,
  total,
  totalSasaran,
  tk,
  ls,
  pp,
  mu,
  ku,
  tkPercentage,
  lsPercentage,
  ppPercentage,
  muPercentage,
  kuPercentage,
}) => {
  return (
    <Box px="xl" pt="xl" pb="xl" borderRadius={6} backgroundColor="card">
      <Text display="block" color="accents08" variant={700}>
        {judul}
      </Text>
      <Text
        textAlign="right"
        display="block"
        variant={1100}
        color="success02"
        fontFamily="monospace"
        mt="sm"
      >
        {formatNumber(total) || EMPTY_DASH}
      </Text>
      <Text display="block" variant={400} color="accents06" textAlign="right">
        Dosis telah diberikan
      </Text>
      <ProgressWrapper mt="sm">
        <Box display="flex" flex={1} borderRadius={6} backgroundColor="progressBar">
          <Box
            height={30}
            borderRadius={6}
            borderTopRightRadius={0}
            borderBottomRightRadius={0}
            backgroundColor="warning02"
            width={`${tkPercentage}%`}
          />
          <Box
            height={30}
            borderRadius={0}
            backgroundColor="primary02"
            width={`${lsPercentage}%`}
          />
          <Box
            height={30}
            borderRadius={0}
            backgroundColor="highlight03"
            width={`${ppPercentage}%`}
          />
          <Box
            height={30}
            borderRadius={0}
            backgroundColor="highlight01"
            width={`${muPercentage}%`}
          />
          <Box
            height={30}
            borderRadius={0}
            backgroundColor="highlight02"
            width={`${kuPercentage}%`}
          />
        </Box>
        <Box display="flex" width={100} alignItems="center" justifyContent="flex-end">
          <Text fontFamily="monospace" variant={500} color="success02">
            {formatNumber(all) || EMPTY_DASH} %
          </Text>
        </Box>
      </ProgressWrapper>
      <Text display="block" mt="sm" mb="sm" variant={400} color="accents06">
        {formatNumber(all) || EMPTY_DASH} % dari {formatNumber(totalSasaran) || EMPTY_DASH} telah
        divaksin
      </Text>

      <VaksinTextPercentage
        title="Tenaga Kesehatan"
        color="warning02"
        percentageValue={`${formatNumber(tk) || EMPTY_DASH} %`}
      />
      <VaksinTextPercentage
        title="Lanjut Usia"
        color="primary02"
        percentageValue={`${formatNumber(ls) || EMPTY_DASH} %`}
      />
      <VaksinTextPercentage
        title="Petugas Publik"
        color="highlight03"
        percentageValue={`${formatNumber(pp) || EMPTY_DASH} %`}
      />
      <VaksinTextPercentage
        title="Masyarakat Rentan dan Umum"
        color="highlight01"
        percentageValue={`${formatNumber(mu) || EMPTY_DASH} %`}
      />
      <VaksinTextPercentage
        title="Kelompok Usia 12-17 tahun"
        color="highlight02"
        percentageValue={`${formatNumber(ku) || EMPTY_DASH} %`}
      />
    </Box>
  );
};

export interface VaccinateSectionBlockProps {
  data?: VaksinStats;
  error: boolean;
  timestamp?: Date;
}

const VaccinateSectionBlock: React.FC<VaccinateSectionBlockProps> = React.memo(
  ({ data, error, timestamp, ...rest }) => {
    if (error) {
      Sentry.withScope(scope => {
        scope.setTag('api_error', 'vaksin_summary');
        Sentry.captureException(error);
      });
    }

    const DetailsWrapper = styled(Box)`
      ${themeProps.mediaQueries.md} {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
      }
    `;

    return (
      <HomepageWidget
        title="Situasi Vaksinasi COVID-19 Saat Ini"
        readMoreText="Lihat Lebih Lengkap"
        href="/vaksin"
        {...rest}
      >
        <GridWrapper>
          <Vaksinasi
            judul="Vaksinasi Dosis 1"
            total={data?.vaksinasi1}
            totalSasaran={data?.total_sasaran_vaksinasi}
            all={data ? percentage(data['vaksinasi1'], data.total_sasaran_vaksinasi) : 0}
            tk={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.sdm_kesehatan['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_sdmk
                  )
                : 0
            }
            tkPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.sdm_kesehatan['total_vaksinasi1'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            ls={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.lansia['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_lansia
                  )
                : 0
            }
            lsPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.lansia['total_vaksinasi1'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            pp={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.petugas_publik['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_petugas_publik
                  )
                : 0
            }
            ppPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.petugas_publik['total_vaksinasi1'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            mu={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.masyarakat_umum['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_masyarakat_umum
                  )
                : 0
            }
            muPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.masyarakat_umum['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_masyarakat_umum
                  )
                : 0
            }
            ku={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.kelompok_usia_12_17['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_kelompok_1217
                  )
                : 0
            }
            kuPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.kelompok_usia_12_17['total_vaksinasi1'],
                    data?.sasaran_vaksinasi_kelompok_1217
                  )
                : 0
            }
          />

          <Vaksinasi
            judul="Vaksinasi Dosis 2"
            total={data?.vaksinasi2}
            totalSasaran={data?.total_sasaran_vaksinasi}
            all={data ? percentage(data['vaksinasi2'], data.total_sasaran_vaksinasi) : 0}
            tk={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.sdm_kesehatan['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_sdmk
                  )
                : 0
            }
            tkPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.sdm_kesehatan['total_vaksinasi2'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            ls={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.lansia['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_lansia
                  )
                : 0
            }
            lsPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.lansia['total_vaksinasi2'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            pp={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.petugas_publik['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_petugas_publik
                  )
                : 0
            }
            ppPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.petugas_publik['total_vaksinasi2'],
                    data?.total_sasaran_vaksinasi
                  )
                : 0
            }
            mu={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.masyarakat_umum['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_masyarakat_umum
                  )
                : 0
            }
            muPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.masyarakat_umum['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_masyarakat_umum
                  )
                : 0
            }
            ku={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.kelompok_usia_12_17['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_kelompok_1217
                  )
                : 0
            }
            kuPercentage={
              data
                ? percentage(
                    data?.tahapan_vaksinasi.kelompok_usia_12_17['total_vaksinasi2'],
                    data?.sasaran_vaksinasi_kelompok_1217
                  )
                : 0
            }
          />
        </GridWrapper>
        <DetailsWrapper>
          <Box mt="md">
            <Text as="h5" m={0} variant={200} color="accents04" fontWeight={400}>
              {error ? ERROR_TITLE : 'Pembaruan Terakhir'}
            </Text>
            <Text as="p" variant={400} color="accents07" fontFamily="monospace">
              {timestamp ? formatTime(new Date(timestamp), 'longest') : ERROR_MESSAGE}
            </Text>
          </Box>
        </DetailsWrapper>
      </HomepageWidget>
    );
  }
);

const VaccinateSection: React.FC<BoxProps> = props => {
  const { data, error } = useSWR<VaksinResponse | undefined>(CEKDIRI_API_URL, fetch);
  const clear = data?.monitoring[data.monitoring.length - 1];
  return (
    <VaccinateSectionBlock data={clear} timestamp={data?.last_updated} error={!!error} {...props} />
  );
};

export default VaccinateSection;
